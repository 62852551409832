<template>
  <div>
    <h1 class="page__title">Пользователь {{ userName }}</h1>

    <div class="intro-y box p-5 mt-5">
      <form
        method="post"
        class="grid grid-cols-12 gap-6"
        :class="{ 'disabled-form': loading }"
      >
        <div
          v-if="loading"
          class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center"
        >
          <Preloader/>
        </div>

        <div class="col-span-6">
          <label for="form-name" class="form-label">Имя</label>
          <input
            id="form-name"
            v-model='form.name'
            type="text"
            class="form-control w-full"
            placeholder=""
            autocomplete="off"
            required
          >
        </div>

        <div class="col-span-6">
          <label for="form-email" class="form-label">Email</label>
          <input
            id="form-email"
            v-model='form.email'
            type="email"
            class="form-control w-full"
            placeholder=""
            autocomplete="off"
            required
          >
        </div>

        <div class="col-span-6 relative">
          <label for="form-password" class="form-label">Пароль</label>
          <input
            id="form-password"
            v-model='form.password'
            type="password"
            class="form-control w-full"
            :disabled="!changePassword"
            placeholder=""
          >
          <div v-if="!changePassword" class="change__password">
            <a href="#" class="btn btn-secondary" @click.prevent="changePassword = !changePassword">
              Сменить пароль
            </a>
          </div>
        </div>

        <div class="col-span-6">
          <label for="form-role" class="form-label">Роль</label>
          <select
            name="roles"
            id="form-role"
            v-model="form.role"
            class="form-select sm:mr-2"
            required
          >
            <option v-for="opt in roles" :value="opt.id">{{ opt.title }}</option>
          </select>
        </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
          <router-link :to="{name: 'users'}" class="btn btn-secondary w-24 mb-2 mr-2">
            Отмена
          </router-link>
          <button type="submit" class='btn btn-primary mb-2 mr-2'  @click.prevent="save(false)">Сохранить и выйти</button>
          <button type="submit" class='btn btn-primary mb-2'  @click.prevent="save(true)">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {usersMixin} from '@/mixins/form/users-mixin.js';
import {errorResponse} from '@/mixins/form/form-mixin.js';
import Preloader from '@/components/preloader/Main.vue';

export default {
    name: 'Edit',
    components: { Preloader },
    mixins: [usersMixin, errorResponse],
    data() {
      return {
        userName: null,
        changePassword: false,
      };
    },
    async created() {
      await this.fetchUser();
      await this.fetchRoles();
      this.userName = this.form.name;
    },
    methods: {
      async save(flag) {
        try {
          const data = await this.axios.post('/users/' + this.$route.params.id, { ...this.form }, {
            params: {
              _method: 'patch',
            },
          });
          if(flag){
            this.$notify('Запись сохранена');
          }else {
            await this.$router.push({name: 'users'});
          }
        } catch (ex) {
          this.errorResponse(ex);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .change__password {
    position: absolute;
    top: 42%;
    right: 0;
  }
</style>
